<template>
  <v-app>
    <v-main>
      <v-container style="height: 100%;">
        <v-row>
          <div class="top-search-icon" @click.stop="drawer = !drawer" v-if="isMobile">
            <v-icon>mdi-magnify</v-icon>
          </div>
          <v-navigation-drawer v-model="drawer" temporary fixed>
            <div class="my-5" style="padding: 0 16px;">
              <v-text-field
                hide-details
                prepend-icon="mdi-magnify"
                single-line
                label="Szukaj"
                v-model="searchWords"
                color="success"
                clearable
                autofocus
                @keyup.enter="goToMainPage()"
              ></v-text-field>
            </div>
            <div class="mt-3">
              <v-list shaped>
                <v-list-group
                  prepend-icon="mdi-folder-search-outline"
                  no-action
                  dense
                  color="success"
                >
                  <template v-slot:activator>
                    <v-list-item-content>
                      <v-list-item-title>Fitry</v-list-item-title>
                    </v-list-item-content>
                  </template>

                  <v-list-item>
                    <template v-slot:default="{ active }">
                      <v-list-item-action>
                        <v-checkbox
                          :input-value="active"
                          v-model="filterTitle"
                          color="success"
                        ></v-checkbox>
                      </v-list-item-action>

                      <v-list-item-content>
                        <v-list-item-title>Tytuł</v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </v-list-item>
                  <v-list-item>
                    <template v-slot:default="{ active }">
                      <v-list-item-action>
                        <v-checkbox
                          :input-value="active"
                          v-model="filterDescriptions"
                          color="success"
                        ></v-checkbox>
                      </v-list-item-action>

                      <v-list-item-content>
                        <v-list-item-title>Opis</v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </v-list-item>
                  <v-list-item>
                    <template v-slot:default="{ active }">
                      <v-list-item-action>
                        <v-checkbox
                          :input-value="active"
                          v-model="filterIngredients"
                          color="success"
                        ></v-checkbox>
                      </v-list-item-action>

                      <v-list-item-content>
                        <v-list-item-title>Składniki</v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </v-list-item>
                </v-list-group>
                <v-list-group prepend-icon="mdi-silverware-fork-knife" no-action color="success">
                  <template v-slot:activator>
                    <v-list-item-content>
                      <v-list-item-title>Kategorie</v-list-item-title>
                    </v-list-item-content>
                  </template>

                  <v-list-item-group v-model="selectedCategory" color="success">
                    <v-list-item
                      v-for="categorie in recipesCategories"
                      :key="categorie"
                      link
                      @click="goToMainPage()"
                    >
                      <v-list-item-icon>
                        <v-icon color="success">mdi-star</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title v-text="categorie"></v-list-item-title>
                    </v-list-item>
                  </v-list-item-group>
                </v-list-group>
              </v-list>
            </div>
          </v-navigation-drawer>
          <v-col cols="12" sm="12" md="3" lg="3" xl="3">
            <div sticky-offset="{top: 25}">
              <router-link to="/">
                <v-img
                  :src="require('./assets/logo_wegaluna.png')"
                  class="my-3"
                  contain
                  height="200"
              /></router-link>
              <div class="my-5" style="padding: 0 16px;" v-if="!isMobile">
                <v-text-field
                  hide-details
                  prepend-icon="mdi-magnify"
                  single-line
                  label="Szukaj"
                  v-model="searchWords"
                  color="success"
                  clearable
                  @keyup.enter="goToMainPage()"
                ></v-text-field>
              </div>
              <div class="mt-3" v-if="!isMobile">
                <v-list shaped>
                  <v-list-group
                    prepend-icon="mdi-folder-search-outline"
                    no-action
                    dense
                    color="success"
                  >
                    <template v-slot:activator>
                      <v-list-item-content>
                        <v-list-item-title>Fitry</v-list-item-title>
                      </v-list-item-content>
                    </template>

                    <v-list-item>
                      <template v-slot:default="{ active }">
                        <v-list-item-action>
                          <v-checkbox
                            :input-value="active"
                            v-model="filterTitle"
                            color="success"
                          ></v-checkbox>
                        </v-list-item-action>

                        <v-list-item-content>
                          <v-list-item-title>Tytuł</v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </v-list-item>
                    <v-list-item>
                      <template v-slot:default="{ active }">
                        <v-list-item-action>
                          <v-checkbox
                            :input-value="active"
                            v-model="filterDescriptions"
                            color="success"
                          ></v-checkbox>
                        </v-list-item-action>

                        <v-list-item-content>
                          <v-list-item-title>Opis</v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </v-list-item>
                    <v-list-item>
                      <template v-slot:default="{ active }">
                        <v-list-item-action>
                          <v-checkbox
                            :input-value="active"
                            v-model="filterIngredients"
                            color="success"
                          ></v-checkbox>
                        </v-list-item-action>

                        <v-list-item-content>
                          <v-list-item-title>Składniki</v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </v-list-item>
                  </v-list-group>
                  <v-list-group prepend-icon="mdi-silverware-fork-knife" no-action color="success">
                    <template v-slot:activator>
                      <v-list-item-content>
                        <v-list-item-title>Kategorie</v-list-item-title>
                      </v-list-item-content>
                    </template>

                    <v-list-item-group v-model="selectedCategory" color="success">
                      <v-list-item
                        v-for="categorie in recipesCategories"
                        :key="categorie"
                        link
                        @click="goToMainPage()"
                      >
                        <v-list-item-icon>
                          <v-icon color="success">mdi-star</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title v-text="categorie"></v-list-item-title>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list-group>
                </v-list>
              </div>
            </div>
          </v-col>
          <v-col cols="12" sm="12" md="9" lg="9" xl="9" style="display: flex;">
            <router-view></router-view>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <v-footer
      padless
      style="background-color: green; color: white; border-top-right-radius: 32px; border-top-left-radius: 32px"
    >
      <v-col class="text-center d-flex justify-space-between px-5" cols="12">
        <div class="ms-2 d-flex align-center">
          <span><strong>wegaluna.pl</strong>&nbsp;-&nbsp;{{ new Date().getFullYear() }}r.</span>
        </div>
        <v-btn fab x-small color="primary" @click="goToFacebook">
          <v-icon>
            mdi-facebook
          </v-icon>
        </v-btn>
      </v-col>
    </v-footer>
  </v-app>
</template>

<script>
import importerdRecipes from "./components/recipes/db.json";

export default {
  name: "App",

  components: {},

  data: () => ({
    searchWords: "",
    filterTitle: true,
    filterDescriptions: true,
    filterIngredients: false,
    recipes: importerdRecipes,
    selectedCategoryData: 0,
    drawer: false,
    windowHeight: window.innerHeight,
  }),
  computed: {
    isMobile() {
      if (this.windowHeight < 960) {
        return true;
      } else return false;
    },
    recipesCategories() {
      let categories = ["Wszystkie"];
      for (const recipe of Object.values(this.recipes)) {
        for (const category of recipe.categories) {
          let isCategorieAdded = categories.includes(category);
          if (!isCategorieAdded) {
            categories.push(category);
          }
        }
      }
      return categories;
    },
    selectedCategory: {
      get() {
        return this.selectedCategoryData;
      },
      set(val) {
        // console.log(this.selectedCategoryData);
        if (val !== undefined) {
          this.selectedCategoryData = val;
          // console.log(val);
          if (this.recipesCategories) {
            this.$store.commit("setSelectedCategory", this.recipesCategories[val]);
          }
        }
      },
    },
  },
  methods: {
    goToMainPage() {
      this.drawer = false;
      if (this.$route.name !== "Home") {
        this.$router.push("/");
      }
    },
    goToFacebook() {
      this.drawer = false;
      window.location.href = "https://www.facebook.com/wega.Luna.bez.glutenu/";
    },
    handleResize() {
      this.windowHeight = window.innerWidth;
    },
  },
  watch: {
    searchWords(val) {
      this.$store.commit("setSearchWords", val);
    },
    filterTitle(val) {
      this.$store.commit("setActiveFilterTitle", val);
    },
    filterDescriptions(val) {
      this.$store.commit("setActiveFilterDescriptions", val);
    },
    filterIngredients(val) {
      this.$store.commit("setActiveFilterIngredients", val);
    },
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>

<style lang="scss">
.top-search-icon {
  position: fixed;
  top: 10px;
  right: 30px;
  padding: 10px;
  background-color: #4caf50;
  border-radius: 50%;
  border: 2px solid white;
  z-index: 30;
  cursor: pointer;

  i {
    color: white !important;
  }
}

.v-navigation-drawer {
  z-index: 999999 !important;
}
</style>
