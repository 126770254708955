<template>
  <v-container>
    <v-row>
      <v-col cols="12" v-if="recipe">
        <v-card outlined>
          <v-img
            :src="require('../assets/recipes/' + recipe.image)"
            height="600px"
          ></v-img>
          <v-card-title>
            {{ recipe.title }}
          </v-card-title>
          <v-card-text class="text--primary text-start">
            <v-row>
              <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                <div
                  v-for="ingredientList in recipe.ingredients"
                  :key="ingredientList.title"
                  class="mb-2"
                >
                  <div>{{ ingredientList.title }}</div>
                  <ul
                    v-for="ingredient in ingredientList.list"
                    :key="ingredient"
                  >
                    <li>{{ ingredient }}</li>
                  </ul>
                </div>
              </v-col>
              <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                <div v-for="(description, i) in recipe.descriptions" :key="i">
                  <p>{{ description }}</p>
                </div>
                <div v-if="recipe.tips">
                  <div
                    v-for="tipsList in recipe.tips"
                    :key="tipsList.title"
                    class="mb-2"
                  >
                    <div>{{ tipsList.title }}:</div>
                    <ul v-for="tip in tipsList.list" :key="tip">
                      <li>{{ tip }}</li>
                    </ul>
                  </div>
                </div>
              </v-col>
            </v-row>
            <v-row> </v-row>
          </v-card-text>
        </v-card>
        <div class="d-flex justify-center flex-column align-center mt-2">
          <p class="text-center mb-0 juice-link">
            <a href="https://ko-fi.com/wegaluna" class="text-decoration-none">
              Jeżeli ten przepis Ci się spotobał, będzie mi miło jak postawisz
              mi sok</a
            >
          </p>
          <a href="https://ko-fi.com/wegaluna" target="_blank">
            <img
              src="https://storage.ko-fi.com/cdn/brandasset/kofi_s_logo_nolabel.png"
              height="45"
              width="45"
            />
          </a>
        </div>
      </v-col>
      <v-col cols="12" v-else>
        <v-card outlined>
          <v-card-text class="text--primary text-start"
            >Brak przepisu</v-card-text
          >
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import importerdRecipes from "../components/recipes/db.json";

export default {
  name: "RecipeSingleView",

  data: () => ({
    recipes: importerdRecipes,
  }),
  metaInfo() {
    return {
      title: this.recipe
        ? `${this.recipe.title} - wegaLuna.pl - Smaczne i zdrowe dania bez glutenu`
        : "wegaLuna.pl - Smaczne i zdrowe dania bez glutenu",
    };
  },
  computed: {
    recipe() {
      if (this.$route.params.slug) {
        let flteterRecipe = this.recipes[this.$route.params.slug];
        if (flteterRecipe) {
          return flteterRecipe;
        } else return "";
      } else return "";
    },
  },
  created() {
    window.scrollTo(0, 0);
  },
};
</script>

<style lang="scss">
.juice-link {
  a {
    color: green !important;
  }
}
</style>
