import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "roboto-fontface/css/roboto/roboto-fontface.css";
import "@mdi/font/css/materialdesignicons.css";
import Sticky from "vue-sticky-directive";
import VueMeta from "vue-meta";
import VueI18n from "vue-i18n";
import { App as CapacitorApp } from "@capacitor/app";

Vue.use(VueI18n);

// import installI18n from "@/lang";

Vue.config.productionTip = false;

Vue.use(Sticky);
Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true
});

const messages = {
  en: {
    message: {
      hello: "hello world"
    }
  },
  ja: {
    message: {
      hello: "こんにちは、世界"
    }
  }
};

// Create VueI18n instance with options
const i18n = new VueI18n({
  locale: "ja", // set locale
  messages // set locale messages
});

CapacitorApp.addListener("backButton", ({ canGoBack }) => {
  if (!canGoBack) {
    CapacitorApp.exitApp();
  } else {
    window.history.back();
  }
});

new Vue({
  router,
  store,
  // installI18n,
  i18n,
  vuetify,
  render: h => h(App)
}).$mount("#app");
