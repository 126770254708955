<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <h1>Polityka Prywatności</h1>
      </v-col>
      <v-col cols="12">
        <v-card outlined>
          <v-card-text class="text--primary text-start">
            Administratorem aplikacji wegaLuna jest DDG Studio z siedzibą w
            Radzyniu Podlaskim.<br />
            Aby się skontaktować wyślij maila na adres ddg.studio.web@gmail.com
          </v-card-text>
          <v-card-text class="text--primary text-start">
            Aplikacja wegaLuna nie zbiera i nie udostępnia danych osobowych.
          </v-card-text>
          <v-card-text class="text--primary text-start">
            DDG Studio zastrzega sobie prawo zmiany niniejszej polityki
            prywatności, w przypadku istotnych przyczyn zewnętrznych (np. zmiany
            obowiązujących przepisów prawa). Aktualnie obowiązująca wersja
            polityki prywatności jest dostępna pod adresem:
            wegaluna.pl/polityka-prywatnosci
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "PrivacyPolicyView",
  data: () => ({}),
  metaInfo() {
    return {
      title: `wegaLuna.pl - Polityka prywatności`
    };
  }
};
</script>
