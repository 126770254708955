<template>
  <v-container>
    <div style="display: flex">
      <span>Kategoria:</span>
      <div class="category-on-reciepes-view" @click="RemoveCategory()">
        {{ selectedCategory }}
        <v-icon v-if="selectedCategory !== 'Wszystkie'">mdi-close</v-icon>
      </div>
      <span v-if="searchWords">Szukasz:</span>
      <div
        class="category-on-reciepes-view"
        @click="ClearSearch()"
        v-if="searchWords"
      >
        {{ searchWords }}
        <v-icon>mdi-close</v-icon>
      </div>
    </div>
    <v-row v-if="filteredRecipes.length">
      <v-col
        cols="12"
        sm="6"
        md="6"
        lg="4"
        xl="3"
        v-for="data in filteredRecipes"
        :key="data.title"
      >
        <v-card style="height: 100%;">
          <router-link :to="'/przepisy/' + data.slug">
            <v-img
              :src="require('../assets/recipes/' + data.image_md)"
              height="230px"
            ></v-img>
          </router-link>

          <v-card-title
            style="word-break: normal; justify-content: center; text-align: center;"
          >
            {{ data.title }}
          </v-card-title>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col cols="12" sm="12" md="12" lg="12" xl="12">
        <v-card>
          <v-card-title
            style="word-break: normal; justify-content: center; text-align: center;"
          >
            Brak przepisów
          </v-card-title>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import importerdRecipes from "./recipes/db.json";

export default {
  name: "HelloWorld",

  data: () => ({
    recipes: importerdRecipes
  }),
  computed: {
    activeFilterTitle() {
      if (this.$store.state.activeFilterTitle) {
        return this.$store.state.activeFilterTitle;
      } else return false;
    },
    activeFilterDescriptions() {
      if (this.$store.state.activeFilterDescriptions) {
        return this.$store.state.activeFilterDescriptions;
      } else return false;
    },
    activeFilterIngredients() {
      if (this.$store.state.activeFilterIngredients) {
        return this.$store.state.activeFilterIngredients;
      } else return false;
    },
    selectedCategory() {
      if (this.$store.state.selectedCategory) {
        return this.$store.state.selectedCategory;
      } else return 0;
    },
    categoryFilteredRecipes() {
      if (this.recipes) {
        if (this.selectedCategory === "Wszystkie") {
          return Object.values(this.recipes).reverse();
        } else {
          return Object.values(this.recipes).filter(recipe => {
            let inCategories = recipe.categories.filter(category => {
              return category.includes(this.selectedCategory);
            });
            return inCategories.length;
          });
        }
      } else return [];
    },
    filteredRecipes() {
      if (this.categoryFilteredRecipes) {
        return this.categoryFilteredRecipes.filter(recipe => {
          let inTtile = this.activeFilterTitle
            ? recipe.title
                .toLowerCase()
                .includes(this.searchWords.toLowerCase())
            : false;
          let inDescriptions = this.activeFilterDescriptions
            ? recipe.descriptions.filter(description => {
                return description
                  .toLowerCase()
                  .includes(this.searchWords.toLowerCase());
              })
            : false;
          // console.log("inTtile", inTtile);
          // console.log("inCategories", inCategories);
          // console.log("inDescriptions", inDescriptions.length);
          return inTtile || inDescriptions.length;
        });
      } else {
        return [];
      }
    },
    searchWords() {
      if (this.$store.state.searchWords) {
        return this.$store.state.searchWords;
      } else return "";
    }
  },
  methods: {
    RemoveCategory() {
      if (this.selectedCategory !== "Wszystkie") {
        this.$store.commit("setSelectedCategory", "Wszystkie");
      }
    },
    ClearSearch() {
      if (this.searchWords) {
        this.$store.commit("setSearchWords", "");
      }
    }
  },
  created() {
    window.scrollTo(0, 0);
  }
  // filters: {
  //   search(searchWords) {
  //     if (!value) return "";
  //     value = value.toString();
  //     return value.charAt(0).toUpperCase() + value.slice(1);
  //   },
  // },
};
</script>

<style lang="scss">
.category-on-reciepes-view {
  display: flex;
  margin-left: 5px;
  margin-right: 20px;
  padding: 2px 10px;
  background-color: #4caf50;
  border-radius: 10px;
  font-size: 0.9rem;
  color: white;
  cursor: pointer;
  i {
    margin-left: 5px;
    color: white !important;
    font-size: 1rem !important;
  }
}
</style>
