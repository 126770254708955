<template>
  <RecipesView />
</template>

<script>
// @ is an alias to /src
import RecipesView from "@/components/RecipesView.vue";

export default {
  name: "Home",
  components: {
    RecipesView
  },
  metaInfo: {
    title: "wegaLuna.pl - Smaczne i zdrowe dania bez glutenu"
  }
};
</script>
