import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    searchWords: "",
    activeFilterTitle: true,
    activeFilterDescriptions: true,
    activeFilterIngredients: false,
    selectedCategory: "Wszystkie"
  },
  mutations: {
    setSearchWords(state, searchWords) {
      state.searchWords = searchWords;
    },
    setActiveFilterTitle(state, activeFilterTitle) {
      state.activeFilterTitle = activeFilterTitle;
    },
    setActiveFilterDescriptions(state, activeFilterDescriptions) {
      state.activeFilterDescriptions = activeFilterDescriptions;
    },
    setActiveFilterIngredients(state, activeFilterIngredients) {
      state.activeFilterIngredients = activeFilterIngredients;
    },
    setSelectedCategory(state, selectedCategory) {
      state.selectedCategory = selectedCategory;
    }
  },
  actions: {
    async test({ commit }, searchWords) {
      this.isBusy = true;
      try {
        commit("setSearchWords", searchWords);
      } catch (err) {
        console.log(err);
      }
    }
  },
  modules: {}
});
