import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import RecipeSingleView from "../views/RecipeSingleView.vue";
import PrivacyPolicyView from "../views/PrivacyPolicyView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/przepisy/:slug",
    name: "Recipe",
    component: RecipeSingleView
  },
  {
    path: "/polityka-prywatnosci",
    name: "Policy",
    component: PrivacyPolicyView
  },
  {
    path: "*",
    redirect: "/"
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
